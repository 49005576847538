// @ts-strict-ignore
import { ComponentType, useCallback, useLayoutEffect, useMemo } from "react";
import { TableVirtuoso } from "react-virtuoso";

import { TableProps } from "components/ui/table-final-saviour/Table/Table";
import { TableCell as TableCellDefault } from "components/ui/table-final-saviour/Table/TableCell";
import { createActionExpandColumn } from "components/ui/table-final-saviour/Table/columns/action-expand.table-column";
import { useAutoHeight } from "components/ui/table-final-saviour/Table/hooks/useAutoHeight";
import { useExpandableRows } from "components/ui/table-final-saviour/Table/hooks/useExpandableRows";
import { useTableComponents } from "components/ui/table-final-saviour/Table/hooks/useTableComponents";
import {
    ExpandableRow,
    ExpandedContentComponent,
} from "components/ui/table-final-saviour/Table/table-expandable.types";
import { Column, GetRowId, Row, TableCellBaseProps } from "components/ui/table-final-saviour/Table/table.types";

interface Props<T extends Row> extends TableProps<T> {
    // Callback to get the unique id of a row.
    // This is used to keep track of which rows are expanded.
    getRowId: GetRowId<T>;
    ExpandedContent: ExpandedContentComponent<T>;
}

const TableExpandable = <T extends Row>(props: Props<T>) => {
    const { columns, maxHeight, ExpandedContent, getRowId } = props;

    const { getIsExpandedRow, toggleExpandedRow, updateExpandedRows } = useExpandableRows({
        getRowId,
    });

    const data = props.data;
    const dataWithExpandedContent: ExpandableRow<T>[] = useMemo(
        () =>
            data.flatMap((row, index) => {
                const isExpanded = getIsExpandedRow(row);
                if (isExpanded) {
                    return [
                        { ...row, _type: "row" as const, _rowIndex: index },
                        { ...row, _type: "expandable_content" as const, _rowIndex: index },
                    ];
                } else {
                    return [{ ...row, _type: "row" as const, _rowIndex: index }];
                }
            }),
        [data, getIsExpandedRow]
    );

    const columnsWithExpandAction: Column<T>[] = useMemo(
        () => [createActionExpandColumn<T>({ getIsExpandedRow, toggleExpandedRow }), ...columns],
        [columns, getIsExpandedRow, toggleExpandedRow]
    );

    const { ref, height, hideScrollbar } = useAutoHeight({ maxHeight, rowCount: props.data.length });
    const components = useTableComponents({
        components: props.components,
        ref,
        columns: columnsWithExpandAction,
        hideScrollbar,
    });

    const itemContent = useCallback(
        (rowIndex: number, row: Row) => {
            const isExpandedContent = row._type === "expandable_content";

            if (isExpandedContent) {
                const columnCount = columnsWithExpandAction.length;
                return (
                    <td colSpan={columnCount} style={{ padding: 0 }}>
                        <ExpandedContent row={row} />
                    </td>
                );
            } else {
                return columnsWithExpandAction.map((column, columnIndex) => {
                    const TableCell: ComponentType<TableCellBaseProps<T>> =
                        column.components?.TableCell ?? TableCellDefault;
                    return (
                        <TableCell
                            key={`cell-${rowIndex}-${columnIndex}`}
                            row={row}
                            rowIndex={row._rowIndex}
                            column={column}
                            columnIndex={columnIndex}
                        />
                    );
                });
            }
        },
        [columnsWithExpandAction, ExpandedContent]
    );

    // This makes sure that the expanded content is not re-mounted when the state of rows above it changes
    const computeItemKey = useCallback((_: unknown, row: Row) => {
        return `${row._rowIndex}-${row._type}}`;
    }, []);

    // Collapse all expanded rows when the data changes
    // With the current setup, the expanded information is tied to the row index,
    // so when the data changes, the new rows with the same index will be expanded
    useLayoutEffect(() => {
        updateExpandedRows(data);
    }, [data, updateExpandedRows]);

    return (
        <div style={{ height }} className={props.className}>
            <TableVirtuoso
                data={dataWithExpandedContent}
                components={components}
                fixedHeaderContent={components.TableHeaderRow}
                itemContent={itemContent}
                computeItemKey={computeItemKey}
            />
        </div>
    );
};

export { TableExpandable };
export type { Props as TableExpandableProps, ExpandedContentComponent as TableExpandedContentComponent };
