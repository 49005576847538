import { TableCellExpandAction } from "components/ui/table-final-saviour/Table/TableCell/TableCellExpandAction";
import { TableHeaderCell } from "components/ui/table-final-saviour/Table/TableHeaderCell";
import { Column, Row } from "components/ui/table-final-saviour/Table/table.types";

interface Options<T extends Row> {
    getIsExpandedRow: (row: T) => boolean;
    toggleExpandedRow: (row: T) => void;
}

export const createActionExpandColumn = <T extends Row>(options: Options<T>): Column<T> => {
    const { getIsExpandedRow, toggleExpandedRow } = options;

    return {
        _type: "action-expand",
        components: {
            TableHeaderCell: (props) => <TableHeaderCell {...props} width={64} />,
            TableCell: (props) => (
                <TableCellExpandAction
                    {...props}
                    isExpanded={getIsExpandedRow(props.row)}
                    onExpand={() => toggleExpandedRow(props.row)}
                />
            ),
        },
    };
};
