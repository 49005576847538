// @ts-strict-ignore
import { WidgetFrame } from "components/layout/WidgetFrame";
import { WidgetWithQueryParameters } from "components/layout/WidgetFrame/widget.types";
import {
    KPIDataSourceChartData,
    KPI,
    KPIDataSourceTextData,
    toKPIChartDataPoints,
    toKPITextData,
} from "components/ui/KPI";
import { useDataSource } from "hooks/useDataSource";
import { routes } from "routes";
import { generatePath } from "routes/routes.utils";

type Props = WidgetWithQueryParameters<"dateFrom" | "dateTo">;

const AttentionCustomersKPIWidget = (props: Props) => {
    const { queryParameters } = props;

    const chartDataSource = useDataSource<KPIDataSourceChartData[]>("AttentionCustomersKpiChart", queryParameters);
    const textDataSource = useDataSource<KPIDataSourceTextData[]>("AttentionCustomersKpiText", queryParameters);
    const dataSources = [chartDataSource, textDataSource];

    const { data, lastDataPoint } = toKPIChartDataPoints(chartDataSource?.data ?? []);
    const additionalValue = toKPITextData(textDataSource?.data ?? []);

    return (
        <>
            <WidgetFrame.Title
                title={"Attention Customers"}
                icon={"lely-icon-human"}
                size={"sm"}
                link={generatePath(routes.attentionCustomers)}
            >
                <WidgetFrame.Title.Information lastImportType={"middleware"} pageId={"333119609"}>
                    Count of unique customers with issues per week/month
                </WidgetFrame.Title.Information>
            </WidgetFrame.Title>
            <WidgetFrame.KPI dependencies={dataSources}>
                <KPI.LastValue title="Last Selected Full Week" value={lastDataPoint?.label} />
                {additionalValue && <KPI.AdditionalValue title={"All Customers"} value={additionalValue} />}
                <KPI.Chart data={data} />
            </WidgetFrame.KPI>
        </>
    );
};

export { AttentionCustomersKPIWidget };
