// @ts-strict-ignore
import { useMemo } from "react";

import { WidgetFrame } from "components/layout/WidgetFrame";
import { useBooleanFilters, BooleanFilter } from "components/ui/BooleanFilters";
import { Chip, ChipVariant } from "components/ui/Chip";
import {
    Column,
    createChipsColumn,
    createDataColumn,
    createDataSortableColumn,
    useSort,
} from "components/ui/table-final-saviour/Table";
import { TableCellLink } from "components/ui/table-final-saviour/Table/TableCell/TableCellLink";
import { useSearch } from "components/ui/table-final-saviour/Table/hooks/useSearch";
import { useDataSource } from "hooks/useDataSource";
import { InsightsWidgetExpandedContent } from "pages/DashboardPage/tss/widgets/InsightsWidget/InsightsWidgetExpandedContent";
import {
    InsightsWidgetDataRow,
    InsightsWidgetDataSourceRow,
} from "pages/DashboardPage/tss/widgets/InsightsWidget/insights-widget.types";
import { routes } from "routes";
import { generatePath } from "routes/routes.utils";

type Props = {
    queryParameters: object;
};

const booleanFilters = [
    {
        key: "agreement",
        title: "Agreement",
        condition: (row) => row.HasContract,
    },
    {
        key: "pmOverdue",
        title: "PM (Over)due",
        condition: (row) => Boolean(row.PMSoon),
    },
] as const satisfies BooleanFilter<InsightsWidgetDataRow>[];

const InsightsWidget = (props: Props) => {
    const { queryParameters } = props;

    const dataSourceParameters = {
        ...queryParameters,
        userRoleLink: "tl",
    };

    const dataSource = useDataSource<InsightsWidgetDataSourceRow[]>("Insights_Table", dataSourceParameters);
    const { data = [] } = dataSource;

    const { sortControls, applySort } = useSort<InsightsWidgetDataRow>({
        initialSortConfig: { key: "InsightCount", order: "desc", dataType: "number" },
    });

    const columns: Column<InsightsWidgetDataRow>[] = useMemo(
        () => [
            createDataSortableColumn({
                dataKey: "CustomerName",
                title: "Customer Name",
                dataType: "string",
                width: 200,
                ...sortControls,
                components: {
                    TableCell: (cellProps) => {
                        const { row } = cellProps;
                        const link = generatePath(routes.customerDetail, {
                            params: {
                                customerNumber: row.CustomerNumber,
                            },
                        });

                        return <TableCellLink to={link} {...cellProps} />;
                    },
                },
            }),
            createDataSortableColumn({
                dataKey: "InsightCount",
                title: "Devices",
                dataType: "number",
                align: "right",
                width: 100,
                ...sortControls,
            }),
            createDataSortableColumn({
                dataKey: "DaysUntilNextPM",
                title: "Days Until Next PM",
                dataType: "number",
                align: "right",
                width: 120,
                ...sortControls,
            }),
            createChipsColumn({
                title: "Insight Categories",
                getChips: (props) => {
                    const { row } = props;
                    const { InsightCategoryList, InsightLevelList } = row;
                    if (!InsightCategoryList) return null;

                    return InsightCategoryList.map((category, index) => {
                        const level = InsightLevelList[index];
                        let variant: ChipVariant = "default";
                        if (level === "red") {
                            variant = "danger";
                        }

                        return <Chip key={index} label={category} variant={variant} />;
                    });
                },
            }),
        ],
        [sortControls]
    );

    const searchColumns: Column<InsightsWidgetDataRow>[] = useMemo(
        () => [
            ...columns,
            createDataColumn({
                dataKey: "InsightCategory",
                title: "Insight Categories",
                dataType: "string",
            }),
        ],
        [columns]
    );

    const booleanFiltersOptions = useBooleanFilters({
        filters: booleanFilters,
        defaultActiveFilters: ["pmOverdue"],
    });
    const { applyFilters } = booleanFiltersOptions;
    const { searchValue, setSearchValue, applySearch } = useSearch({ columns: searchColumns });

    const searchedData = useMemo(() => {
        const parsedData = data.map((row) => {
            const { InsightCategory, InsightLevel } = row;
            const categories = InsightCategory.split(",");
            const levels = InsightLevel.split(",");

            return {
                ...row,
                InsightCategoryList: categories,
                InsightLevelList: levels,
            } as InsightsWidgetDataRow;
        });

        const filteredData = applyFilters(parsedData);

        return applySort(applySearch(filteredData));
    }, [applySearch, applySort, applyFilters, data]);

    return (
        <>
            <WidgetFrame.Title title={"Insight List"} icon={"lely-icon-show"}>
                <WidgetFrame.Title.Search value={searchValue} setValue={setSearchValue} />
                <WidgetFrame.Title.BooleanFilters {...booleanFiltersOptions} />
                <WidgetFrame.Title.Information pageId={"189104158"} />
            </WidgetFrame.Title>
            <WidgetFrame.TableExpandable
                dataSource={{ ...dataSource, data: searchedData }}
                columns={columns}
                maxHeight={800}
                ExpandedContent={InsightsWidgetExpandedContent}
                getRowId={(row) => row.CustomerNumber}
            />
        </>
    );
};

export { InsightsWidget };
