// @ts-strict-ignore
import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import styled from "styled-components";

import { ComboboxInput } from "components/ui/Combobox/ComboboxInput";
import { ComboboxMenu } from "components/ui/Combobox/ComboboxMenu";
import { ALL_ITEM_LABEL } from "components/ui/Combobox/combobox.consts";
import { useContentScroll } from "components/ui/Combobox/combobox.hooks";
import { Item, ComboboxOptions } from "components/ui/Combobox/combobox.types";
import { search } from "components/ui/Combobox/combobox.utils";
import { Popover } from "components/ui/Popover";
import { Portal } from "components/ui-deprecated";
import { usePopover } from "hooks/usePopover";

const ComboboxStyled = styled.div`
    position: relative;

    width: 100%;
`;

type Props = {
    items: Item[];
    value?: string;
    onSelect: (item?: Item) => void;
    className?: string;
    options?: ComboboxOptions;
    zIndex?: number;
};

const Combobox = (props: Props) => {
    const { items: defaultItems, value, onSelect, options, zIndex = 50 } = props;

    const displayNoneAsAll = options?.displayNoneAsAll ?? false;
    const allItemLabel = options.allItemLabel ?? ALL_ITEM_LABEL;

    const items = useMemo(() => {
        if (displayNoneAsAll) {
            // Adds an internal select item, which is automatically selected,
            // when the input value is an empty string
            return [...defaultItems, { value: "", label: allItemLabel }];
        } else {
            return defaultItems;
        }
    }, [defaultItems, displayNoneAsAll, allItemLabel]);

    const emptyInputValue = displayNoneAsAll ? allItemLabel : "";
    const [inputValue, setInputValue] = useState(emptyInputValue);
    const [hasTyped, setHasTyped] = useState(false);
    const filteredItems = hasTyped ? search(items, inputValue) : items;

    const handleChange = (value: string) => {
        setInputValue(value);
        setHasTyped(true);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const isClickInsideMenu = menuRef.current?.contains(event.relatedTarget);
        if (isClickInsideMenu) return;

        const foundItem = items.find((item) => item.value === value);
        if (!foundItem) return;

        if (inputValue === foundItem.label) return;

        onSelect(filteredItems.at(0));
    };

    const handleSelect = (item: Item) => {
        setInputValue(item.label);
        onSelect(item);

        handleMenuClose();
        setHasTyped(false);
    };

    const handleResetValue = useCallback(() => {
        setInputValue(emptyInputValue);
        onSelect();

        setHasTyped(false);
    }, [emptyInputValue, onSelect]);

    // Sync external 'inputValue' and select an item
    useEffect(() => {
        const foundItem = items.find((item) => item.value === value);
        if (!foundItem) return;

        setInputValue(foundItem.label);
    }, [value, items]);

    const menuRef = useRef<HTMLDivElement>(null);
    const {
        ref,
        isOpen: isMenuOpen,
        handleOpen: handleMenuOpen,
        handleToggleOpen: handleMenuToggle,
        handleClose: handleMenuClose,
    } = usePopover();
    useContentScroll(handleMenuClose);

    return (
        <ComboboxStyled ref={ref} className={props.className} onFocus={handleMenuOpen}>
            <ComboboxInput
                value={inputValue}
                onChange={handleChange}
                onBlur={handleBlur}
                isMenuOpen={isMenuOpen}
                onMenuToggleClick={handleMenuToggle}
                onReset={handleResetValue}
                options={options}
            />
            {isMenuOpen && (
                <Portal zIndex={zIndex}>
                    <Popover variant={"bottom"} $isPortal={true} $zIndex={zIndex}>
                        <ComboboxMenu ref={menuRef} items={filteredItems} onSelect={handleSelect} options={options} />
                    </Popover>
                </Portal>
            )}
        </ComboboxStyled>
    );
};

export { Combobox };
export type { Props as ComboboxProps };
