// @ts-strict-ignore
import { WidgetFrame } from "components/layout/WidgetFrame";
import { WidgetWithQueryParameters } from "components/layout/WidgetFrame/widget.types";
import {
    type ChartData,
    chartColor,
    createTimeXAxis,
    createLinearYAxis,
    createLineChartDataset,
    createLineChartConfiguration,
    createTooltipConfiguration,
} from "components/ui/Chart";
import { useDataSource } from "hooks/useDataSource";

type Props = WidgetWithQueryParameters<"dateTo" | "dateFrom">;

// Source: FMSDashboard_HorizonUsage_FarmsRegisteredHorizonKPI_VegaLiteWidget.json
// id: 25660b3c-6c96-4ded-b6eb-a9aa6f3b11ff
const FarmsRegisteredHorizonWidget = (props: Props) => {
    const { queryParameters } = props;
    const title = "Farms registered Horizon";

    const dataDataSource = useDataSource("HorizonUsage_FarmsRegisteredHorizon", {
        ...queryParameters,
    });
    const mainDataSources = [dataDataSource];

    const data = dataDataSource?.data ?? [];

    const chartConfiguration = createLineChartConfiguration({
        scales: {
            x: createTimeXAxis(),
            y: createLinearYAxis(),
        },
        plugins: {
            tooltip: createTooltipConfiguration<any>({
                getLabel: ({ raw }) => `Registered farms/all farms: ${raw["ChartLabel"]}`,
            }),
        },
    });

    const chartData: ChartData = {
        labels: data.map(({ ChartDate }) => ChartDate),
        datasets: [
            createLineChartDataset({
                label: "Farms registered Horizon",
                data: data.map(({ ChartDate, ChartValue, ChartLabel }) => ({
                    x: ChartDate,
                    y: ChartValue,
                    ChartLabel,
                })),
                color: chartColor.darkTeal,
            }),
        ],
    };

    return (
        <>
            <WidgetFrame.Title title={title} icon={"lely-icon-diagram"}>
                <WidgetFrame.Title.Information lastImportType={"middleware"} pageId={"1199243296"} />
            </WidgetFrame.Title>
            <WidgetFrame.Chart configuration={chartConfiguration} data={chartData} dependencies={mainDataSources} />
        </>
    );
};

export { FarmsRegisteredHorizonWidget };
