// @ts-strict-ignore
import { WidgetFrame } from "components/layout/WidgetFrame";
import { WidgetWithQueryParameters } from "components/layout/WidgetFrame/widget.types";
import {
    type Regions,
    KPI,
    getLastValueTitle,
    getThresholdsFromRegions,
    getActiveRegion,
    toKPIChartDataPoints,
} from "components/ui/KPI";
import { useDataSource } from "hooks/useDataSource";
import { routes } from "routes";
import { generatePath } from "routes/routes.utils";

type TextDataRow = {
    DsThresholdHigher: number;
    DsThresholdLower: number;
};

type Props = WidgetWithQueryParameters<"dateFrom" | "dateTo">;

const RepeatVisitCasesKPIWidget = (props: Props) => {
    const { queryParameters } = props;
    const { dateFrom, dateTo } = queryParameters;

    const title = "Repeat Visit Cases";

    const dataSourceParameters = { ...queryParameters, technicianCode: "", KPI: 15 };

    const chartDataSource = useDataSource("Dashboard_SpentHours_Chart", dataSourceParameters);
    const textDataSource = useDataSource<[TextDataRow]>("Dashboard_SpentHours_Text", dataSourceParameters);

    const { data, lastDataPoint } = toKPIChartDataPoints(chartDataSource?.data ?? []);

    const { DsThresholdLower, DsThresholdHigher } = textDataSource.data?.[0] ?? {};

    const regions = [
        { type: "region", color: "red" },
        { type: "threshold", value: DsThresholdHigher, regionIfEqual: "lower" },
        { type: "region", color: "orange" },
        { type: "threshold", value: DsThresholdLower, regionIfEqual: "upper" },
        { type: "region", color: "green" },
    ] satisfies Regions;
    const thresholds = getThresholdsFromRegions({ regions, lastDataPoint });
    const activeRegion = getActiveRegion({ regions, lastDataPoint });
    const color = activeRegion?.color;

    return (
        <>
            <WidgetFrame.Title
                title={title}
                icon={"lely-icon-ongoing"}
                size={"sm"}
                link={generatePath(routes.repeatVisitCases)}
            >
                <WidgetFrame.Title.Information lastImportType={"middleware"} pageId={"233898205"}>
                    Repeat visit is any repair that follows within 15 days a repair or preventive maintenance
                </WidgetFrame.Title.Information>
            </WidgetFrame.Title>
            <WidgetFrame.KPI dependencies={[chartDataSource, textDataSource]}>
                <KPI.LastValue title={getLastValueTitle(dateFrom, dateTo)} value={lastDataPoint?.label} color={color} />
                <KPI.Chart data={data} color={color} thresholds={thresholds} />
            </WidgetFrame.KPI>
        </>
    );
};

export { RepeatVisitCasesKPIWidget };
